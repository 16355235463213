import "./PrivacyScreen.css";
import "./PrivacyScreen.css";
import { Link } from "react-router-dom";
import { chevronBackCircle } from "ionicons/icons";
import { IonIcon } from "@ionic/react";
import { Helmet } from "react-helmet";
import FooterItem from "../FooterItem";

function NewPrivacyScreen() {
  return (
    <div className="privacyContainer">
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <div className="privacyContents">
        <Link to="/" className="backButtonContainer">
          <IonIcon icon={chevronBackCircle} className="backButton" />
        </Link>
        <h1 className="titleItem">Privacy Policy</h1>

        <h2 className="subtitle">Effective March 25, 2024 at 2:00 PM PST</h2>

        <p className="body">
          Welcome! This privacy notice for FIRSTPEEL INC. (“
          <span className="bold">we,</span>” “<span className="bold">us,</span>”
          or “<span className="bold">our</span>”), describes how and why we
          might collect, store, use, and or/share (“
          <span className="bold">process</span>”) your information when you use
          our services (“<span className="bold">Services</span>”), such as when
          you:
          <ul>
            <li>
              Visit our website at <Link to="/">https://www.firstpeel.com</Link>
              .
            </li>
            <li>
              Use any features available on our website, such as the
              functionality where users can register their phone numbers to
              receive an SMS notification regarding the launch of our mobile
              dating app.
            </li>
            <li>
              Interact with our toll-free number (+1 888-698-4880) for services
              such as managing opt-out requests.
            </li>
          </ul>
          <span className="bold">Questions or concerns?</span> Reading this
          privacy notice will help you understand your privacy rights and
          choices. If you do not agree with our policies and practices, please
          do not use our Services. If you still have any questions or concerns,
          please contact us at information@firstpeel.com.
        </p>
        <h1 className="privacyHeader1">Summary Of Key Points</h1>
        <p className="body">
          <span className="bold">
            This summary provides key points from our privacy notice, but you
            can find out more details about any of these topics below.
          </span>
          <br />
          <br />
          <span className="bold">
            What personal information do we process?
          </span>{" "}
          When you visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          and the choices you make. Learn more about{" "}
          <a href="#personalInfo">personal information you disclose to us.</a>
          <br />
          <br />
          <span className="bold">
            Do we process any sensitive personal information?{" "}
          </span>
          We do not process sensitive personal information.
          <br />
          <br />
          <span className="bold">
            Do we receive any information from third parties?
          </span>{" "}
          We do not receive any information from third parties.
          <br />
          <br />
          <span className="bold">How do we process your information?</span> We
          process your information to provide, improve, and administer our
          Services, communicate with you, for promotional purposes, for security
          and fraud prevention, to comply with law, and to manage legal matters.
          We may also process your information for other purposes with your
          consent. We process your information only when we have a valid legal
          reason to do so. Learn more about{" "}
          <a href="#processInfo">how we process your information.</a>
          <br />
          <br />
          <span className="bold">
            In what situations and with which parties do we share personal
            information?
          </span>{" "}
          We may share information in specific situations and with specific
          third parties. Learn more about{" "}
          <a href="#shareInfo">
            when and with whom we share your personal information.
          </a>
          <br />
          <br />
          <span className="bold">How do we keep your information safe?</span> We
          have organizational and technical processes and procedures in place to
          protect your personal information. However, no electronic transmission
          over the internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Learn more about{" "}
          <a href="#safeInfo">how we keep your information safe.</a>
          <br />
          <br />
          <span className="bold">What are your rights?</span> You have certain
          rights regarding your personal information. Learn more about{" "}
          <a href="#privacyRights">your privacy rights.</a>
          <br />
          <br />
          <span className="bold">How do you exercise your rights?</span> The
          easiest way to exercise your rights is by contacting us at
          information@firstpeel.com. We will respond to your request promptly
          and appropriately.
          <br />
          <br />
          Want to learn more about what we do with any information we collect?
          Review the privacy notice in full.
        </p>
        <h1 className="privacyHeader1">1. What Information Do We Collect?</h1>
        <p className="body" id="personalInfo">
          <span className="bold">Personal information you disclose to us</span>
          <br />
          <br />
          <span className="bold">In Short:</span> We collect personal
          information that you provide to us.
          <br />
          <br />
          We collect personal information, in various ways, that you voluntarily
          provide to us when you visit or use any part of our Services.
          <br />
          <br />
          <span className="bold">
            Personal Information Provided by You:
          </span>{" "}
          The personal information that we collect depends on the context of
          your interactions with us and the Services, and the choices you make.
          The personal information we collect may include the following:
          <ul>
            <li>Phone numbers.</li>
            <li>
              Timestamps to record the time when a user submitted their phone
              number.
            </li>
            <li>
              The content of SMS messages sent to our toll-free number (+1
              888-698-4880).
            </li>
          </ul>
          <span className="bold">Sensitive information:</span> We do not process
          sensitive information.
          <br />
          <br />
          All personal information that you provide to us must be true,
          complete, and accurate, and you must notify us of any changes to such
          personal information.
          <br />
          <br />
          <span className="bold">
            Personal information automatically collected:
          </span>
          <br />
          <br />
          <span className="bold">In Short:</span> Some information - such as
          your Internet Protocol (IP) address and/or browser and device
          characteristics - is collected automatically when you interact with
          our Services.
          <br />
          <br />
          We automatically collect certain information through sources like our
          website’s access logs when you visit, use, or navigate our Services.
          This information does not reveal your specific identity (like your
          name or contact information), but is primarily needed to maintain the
          security and operation of our Services, and for our internal analytics
          and reporting purposes. The information we collect may include:
          <ul>
            <li>
              <span className="bold">Technical Data</span> such as date and time
              of access, request ID, host header, protocol used, bytes
              transferred, time taken for the request, content type and length,
              and range of content accessed.
            </li>
            <li>
              <span className="bold">Device and Browser Information</span> such
              as type of device used, device and application identification
              numbers, browser type and settings, operating system, system
              configuration information, and hardware model.
            </li>
            <li>
              <span className="bold">Network and Connection Data</span> such as
              IP address (or proxy server), Internet service provider and/or
              mobile carrier, SSL protocol and cipher used.
            </li>
            <li>
              <span className="bold">Location and Access Patterns</span> such as
              edge location of the request, URLs visited (referrer and target
              URLs), and patterns of service usage (including pages and files
              viewed, searches, and actions taken within our Services).
            </li>
            <li>
              <span className="bold">Error and Performance Data</span> such as
              system activity, error reports (crash dumps), and diagnostic
              information.
            </li>
          </ul>
        </p>
        <h1 className="privacyHeader1" id="processInfo">
          2. How Do We Process Your Information?
        </h1>
        <p className="body">
          <span className="bold">In Short:</span> We process your information to
          provide, improve, and administer our Services, communicate with you,
          for promotional purposes, for security and fraud prevention, to comply
          with law, and to manage legal matters. We may also process your
          information for other purposes with your consent.
          <br />
          <br />
          <span className="bold">
            We process your personal information for a variety for reasons,
            depending on how you interact with our Services, including:
          </span>
          <ul>
            <li>
              <span className="bold">
                To deliver and facilitate delivery of services to the user.
              </span>{" "}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <span className="bold">
                To respond to user inquiries/offer support to users.
              </span>{" "}
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li>
              <span className="bold">
                To evaluate and improve our Services, products, marketing, and
                your experience.
              </span>{" "}
              We may process your information when we believe it is necessary to
              identify usage trends, determine the effectiveness of our
              promotional campaigns, and to evaluate and improve our Services,
              products, marketing, and your experience.
            </li>
            <li>
              <span className="bold">To manage promotional activities.</span> We
              may process your information to determine your eligibility for our
              promotional offers, and to provide promotional activities.
            </li>
            <li>
              <span className="bold">
                To comply with our legal obligations and manage legal matters.
              </span>{" "}
              We may process your information to comply with our legal
              obligations, respond to legal requests, exercise, establish, or
              defend our legal rights, as well as to contact you about matters
              related to your use of our Services.
            </li>
          </ul>
        </p>
        <h1 className="privacyHeader1" id="shareInfo">
          3. When And With Whom Do We Share Your Personal Information?
        </h1>
        <p className="body">
          <span className="bold">In short:</span> We may share information in
          specific situations described in this section and/or with the
          following third parties.
          <br />
          <br />
          <span className="bold">
            Vendors, Consultants, and Other Third-Party Service Providers.
          </span>{" "}
          We may share your data with third-party vendors, service providers,
          contractors, or agents (“<span className="bold">third parties</span>”)
          who perform services for us or on our behalf and require access to
          such information to do that work. We have contracts in place with our
          third parties, which are designed to help safeguard your personal
          information. This means that they cannot do anything with your
          personal information unless we have instructed them to do it. They
          will also not share your personal information with any organization
          apart from us. They also commit to protect the data they hold on our
          behalf and to retain it for the period we instruct. The third parties
          we may share personal information with are as follows:
          <ul>
            <li>
              <span className="bold">Amazon Web Services (AWS):</span> Used for
              cloud computing services, to communicate and chat with users, data
              backup and security, functionality and infrastructure
              optimization, website hosting, and website performance monitoring.
            </li>
          </ul>
          We also may need to share your personal information in the following
          situations:
          <ul>
            <li>
              <span className="bold">Business Transfers:</span> We may share or
              transfer your information in connection with, or during any
              negotiations of, any merger, sale of company assets, financing, or
              acquisition of all or a portion of our business to another
              company.
            </li>
          </ul>
        </p>
        <h1 className="privacyHeader1">
          4. How Long Do We Keep Your Information?
        </h1>
        <p className="body">
          <span className="bold">In Short:</span> We keep your information for
          as long as necessary to fulfill the purposes outlined in this privacy
          notice unless otherwise required by law.
          <br />
          <br />
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax,
          accounting, or other legal requirements). Generally, no purpose in
          this notice will require us keeping your personal information for
          longer than it takes to fully notify all users who registered their
          phone number on <Link to="/">https://www.firstpeel.com</Link> of the
          availability of our mobile app. However, for individuals participating
          in our current promotional offer, we will retain personal information
          for a duration that extends until the conclusion of the activation
          period of the promotion.
          <br />
          <br />
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </p>
        <h1 className="privacyHeader1" id="safeInfo">
          5. How Do We Keep Your Information Safe?
        </h1>
        <p className="body">
          <span className="bold">In short:</span> We aim to protect your
          personal information through a system of organizational and technical
          security measures.
          <br />
          <br />
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </p>
        <h1 className="privacyHeader1">
          6. Do We Collect Information From Minors?
        </h1>
        <p className="body">
          <span className="bold">In short:</span> We do not knowingly collect
          data from or market to children under 18 years of age.
          <br />
          <br />
          We do not knowingly solicit data from or market to children under 18
          years of age. By using the Services, you represent that you are at
          least 18 years of age. If we learn that personal information from
          users less than 18 years of age has been collected, we will take
          reasonable measures to promptly delete such data from our records. If
          you become aware of any data we may have collected from children under
          age 18, please contact us at information@firstpeel.com.
        </p>
        <h1 className="privacyHeader1" id="privacyRights">
          7. What Are Your Privacy Rights?
        </h1>
        <p className="body">
          <span className="bold">In short:</span> You may review, change or
          terminate your account at any time.
          <br />
          <br />
          <span className="bold">Withdrawing your consent:</span> If we are
          relying on your consent to process your personal information, which
          may be express and/or implied consent depending on the applicable law,
          you have the right to withdraw your consent at any time. To withdraw
          your consent, please contact us at information@firstpeel.com with your
          request. We aim to process all consent withdrawal requests within 5
          business days.
          <br />
          <br />
          Upon your request, we will immediately initiate the process to request
          the deletion of your personal data from third-party service providers.
          Please be aware that while we will take immediate action on our end,
          the complete removal of your data from third-party services, such as
          AWS, may not be immediate and might be subject to certain limitations
          like legal obligations or the completion of services already in
          progress. However, withdrawing your consent may result in certain
          functionalities of our Services becoming unavailable to you. In
          addition, please note that this will not affect the lawfulness of the
          processing before its withdrawal nor, when applicable law allows, will
          it affect the processing of your personal information conducted in
          reliance on lawful processing grounds other than consent.
          <br />
          <br />
          If you have questions or comments about your privacy rights, you may
          email us at information@firstpeel.com.
        </p>
        <h1 className="privacyHeader1">
          8. Controls For Do-Not-Track Features
        </h1>
        <p className="body">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track (“DNT”) feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </p>
        <h1 className="privacyHeader1">
          9. Do We Make Updates To This Notice?
        </h1>
        <p className="body">
          <span className="bold">In short:</span> Yes, we will update this
          notice as necessary to reflect changes in our information practices or
          to stay compliant with relevant laws.
          <br />
          <br />
          This privacy policy may be updated periodically and without prior
          notice to you to reflect changes in our information practices or
          relevant laws. We will post a notice on{" "}
          <Link to="/">https://www.firstpeel.com</Link> to notify you of any
          substantive changes to the way we collect and use information. The
          updated version will be indicated by an updated “Effective” date.
        </p>
        <h1 className="privacyHeader1">
          10. How Can You Contact Us About This Notice?
        </h1>
        <p className="body">
          If you have questions or comments about this notice, you may email us
          at information@firstpeel.com.
        </p>
        <h1 className="privacyHeader1">
          11. How Can You Review, Update, Or Delete The Data We Collect From
          You?
        </h1>
        <p className="body">
          You have the right to request access to the personal information we
          collect from you, change that information, or delete it. To request to
          review, update, or delete your personal information, contact us at
          information@firstpeel.com.
        </p>
        <div className="bottomMargin"></div>
      </div>
      <div className="bottomMargin"></div>
      <FooterItem />
    </div>
  );
}

export default NewPrivacyScreen;
