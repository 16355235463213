import HomeScreen from "./Screens/HomeScreen";
import NewTermsScreen from "./Screens/NewTermsScreen";
import NewPrivacyScreen from "./Screens/NewPrivacyScreen";
import ScrollToTop from "./ScrollToTop";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    // Increment the page count on page load
    async function updatePageViewCount() {
      try {
        await fetch(
          "https://s8kgicsb5f.execute-api.us-west-1.amazonaws.com/default/add",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } catch (error) {}
    }
    updatePageViewCount();
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="/newterms" element={<NewTermsScreen />} />
        <Route path="/newprivacy" element={<NewPrivacyScreen />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
